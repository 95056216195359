import { useCallback, useRef } from 'react';
import { AxiosError } from 'axios';
import {
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient
} from 'react-query';
import {
  archiveGivingFormService,
  archiveLegacyEmbedService,
  checkHostedPageSlug,
  createGivingFormService,
  createGivingFormTemplateService,
  deleteBannerImages,
  deleteGivingFormTemplateService,
  deleteImage,
  deleteTemplateShare,
  getAllGivingFormsService,
  getAllTemplateShares,
  getGivingFormByInstanceIdService,
  getGivingFormTemplatesService,
  getGivingFormsForEmailService,
  getHostedPageSlugs,
  saveImage,
  updateBannerImages,
  updateGivingFormRedirectURL,
  updateGivingFormReferenceCode,
  updateGivingFormService,
  updateGivingFormTemplateService,
  updateSmsForm,
  updateTemplateShares,
  validateGivingFormConfigService,
  validateGivingFormNameService
} from 'services/givingFormService';
import { CampaignGivingForm } from 'services/types/campaignTypes';
import {
  BannerImages,
  CheckHostedPageSlugRequestDto,
  DeleteGivingFormTemplate,
  DeleteImage,
  DeleteTemplateSharePostBody,
  GetAllGivingFormsRequest,
  GetAllTemplateSharesRequest,
  GetGivingFormsForEmail,
  GetHostedPageSlugsRequesWithOptionstDto,
  GivingForm,
  GivingFormPostBody,
  GivingFormTemplatePostBody,
  HostedPageSlugsResponseDto,
  TemplateShare,
  UniqueNamePostBody,
  UpdateBannerImagesPost,
  UpdateGivingForm,
  UpdateGivingFormRedirectURLPostBody,
  UpdateGivingFormReferenceCodePostBody,
  UpdateGivingFormTemplate,
  UpdateSmsFormPostBody,
  UpdateTemplateSharePostBody,
  UploadImagePost,
  ValidateGivingFormConfigPostBody
} from 'services/types/givingFormTypes';

export const useGivingForms = ({
  organizationId,
  campaignId = null,
  includeGlobals = false
}: GetAllGivingFormsRequest) =>
  useQuery(
    [
      'getAllGivingForms',
      {
        organizationId,
        campaignId,
        includeGlobals
      }
    ],
    () =>
      getAllGivingFormsService({ organizationId, campaignId, includeGlobals })
  );

export const useGivingFormByInstanceId = (
  embedInstanceId: string,
  queryOptions: UseQueryOptions<GivingForm> = {}
) =>
  useQuery<GivingForm>(
    ['givingFormByInstanceId', { embedInstanceId }],
    () => getGivingFormByInstanceIdService(embedInstanceId),
    { cacheTime: 0, ...queryOptions }
  );

export const useCheckHostedPageSlug = ({
  organizationId,
  slug,
  givingFormId,
  isFromDuplicateForm
}: CheckHostedPageSlugRequestDto) =>
  useMutation(['checkHostedPageSlug'], () =>
    checkHostedPageSlug({
      organizationId,
      slug,
      givingFormId,
      isFromDuplicateForm
    })
  );

export const useGetHostedPageSlugs = ({
  organizationId,
  givingFormId,
  queryOptions
}: GetHostedPageSlugsRequesWithOptionstDto) =>
  useQuery<HostedPageSlugsResponseDto>(
    ['getHostedPageSlugs', { organizationId, givingFormId }],
    () => getHostedPageSlugs({ organizationId, givingFormId }),
    queryOptions
  );

export const useValidateGivingFormName = ({
  organizationId,
  name,
  campaignId,
  givingFormId,
  includeGlobalTemplates
}: UniqueNamePostBody) =>
  useMutation(
    [
      'validateGivingFormNameByName',
      { organizationId, name, campaignId, givingFormId, includeGlobalTemplates }
    ],
    () =>
      validateGivingFormNameService({
        organizationId,
        name,
        campaignId,
        givingFormId,
        includeGlobalTemplates
      })
  );

export const useCreateGivingFormMutation = (givingForm: GivingFormPostBody) => {
  const queryClient = useQueryClient();
  return useMutation(() => createGivingFormService(givingForm), {
    onSuccess: (result: GivingForm) => {
      queryClient.setQueryData(
        ['getAllGivingForms', { organizationId: givingForm.organizationId }],
        (prevState: GivingForm[] | undefined): GivingForm[] => {
          if (prevState) {
            return [...prevState, result];
          }
          return [result];
        }
      );
    }
  });
};
export const useUpdateGivingForm = ({
  givingFormId,
  name,
  config,
  receiptId,
  campaignStartDate,
  organizationId,
  isSmsForm
}: UpdateGivingForm & { campaignStartDate: string }) => {
  const queryClient = useQueryClient();

  return useMutation(
    (isArchived: boolean) =>
      updateGivingFormService({
        givingFormId,
        name,
        config,
        receiptId,
        organizationId,
        isSmsForm,
        isArchived
      }),
    {
      onSuccess: (result: GivingForm) => {
        queryClient.setQueryData(
          ['getAllGivingForms', { organizationId: result.organizationId }],
          (prevState: GivingForm[] | undefined): GivingForm[] => {
            if (prevState) {
              const newState = prevState.filter(
                (form) => form.embedInstanceId !== result.embedInstanceId
              );
              return [...newState, result];
            }
            return [result];
          }
        );
        // After updating a giving form, update the cache for the
        // giving form to view changes on campaign page without reloading
        queryClient.setQueryData(
          [
            'campaignGivingForms',
            {
              organizationId: result.organizationId,
              campaignId: result.campaignId,
              campaignStartDate
            }
          ],
          (
            prevState: CampaignGivingForm[] | undefined
          ): CampaignGivingForm[] => {
            if (prevState) {
              const newState = [...prevState];
              const resultIndex = newState.findIndex(
                (form) => form.id === result.embedInstanceId
              );
              newState.splice(resultIndex, 1, {
                ...prevState[resultIndex],
                name: result.name
              });
              return newState;
            }
            return [];
          }
        );
      }
    }
  );
};

export const useCreateGivingFormTemplateMutation = (
  givingForm: GivingFormTemplatePostBody
) =>
  useMutation<GivingForm, AxiosError>(() =>
    createGivingFormTemplateService(givingForm)
  );

export const useGivingFormTemplates = ({
  organizationId,
  includeGlobals = false,
  includeSharedTemplates = true
}: Pick<
  GetAllGivingFormsRequest,
  'organizationId' | 'includeGlobals' | 'includeSharedTemplates'
>) =>
  useQuery(
    [
      'getGivingFormTemplates',
      {
        organizationId,
        includeGlobals,
        includeSharedTemplates
      }
    ],
    () =>
      getGivingFormTemplatesService({
        organizationId,
        includeGlobals,
        includeSharedTemplates
      })
  );

export const useUpdateGivingFormTemplate = ({
  givingFormTemplateId,
  name,
  config,
  receiptId,
  organizationId
}: UpdateGivingFormTemplate) => {
  const queryClient = useQueryClient();

  return useMutation(
    () =>
      updateGivingFormTemplateService({
        givingFormTemplateId,
        name,
        config,
        receiptId,
        organizationId
      }),
    {
      onSuccess: (result: GivingForm) => {
        queryClient.setQueryData(
          [
            'getGivingFormTemplates',
            { organizationId: result.organizationId, includeGlobals: false }
          ],
          (prevState: GivingForm[] | undefined): GivingForm[] => {
            if (prevState) {
              const newState = prevState.filter(
                (form) => form.embedInstanceId !== result.embedInstanceId
              );
              return [...newState, result];
            }
            return [result];
          }
        );
      }
    }
  );
};

export const useDeleteGivingFormTemplate = ({
  givingFormTemplateId,
  organizationId
}: DeleteGivingFormTemplate) => {
  const queryClient = useQueryClient();

  return useMutation(
    () =>
      deleteGivingFormTemplateService({ givingFormTemplateId, organizationId }),
    {
      onSuccess: () => {
        const filterRemovedTemplate = (
          templates: GivingForm[] | undefined
        ): GivingForm[] => {
          if (templates) {
            return templates.filter(
              (template) => template.embedInstanceId !== givingFormTemplateId
            );
          }
          return undefined;
        };
        queryClient.setQueryData(
          ['getGivingFormTemplates', { organizationId, includeGlobals: false }],
          filterRemovedTemplate
        );
        queryClient.setQueryData(
          ['getGivingFormTemplates', { organizationId, includeGlobals: true }],
          filterRemovedTemplate
        );
      }
    }
  );
};

export const useValidateGivingFormConfig = (
  { organizationId, config, givingFormId }: ValidateGivingFormConfigPostBody,
  useMutationOptions?: UseMutationOptions<boolean, Error>
) =>
  useMutation<boolean, Error>(
    [
      'validateGivingFormConfig',
      {
        organizationId,
        config
      }
    ],
    () =>
      validateGivingFormConfigService({ organizationId, config, givingFormId }),
    useMutationOptions
  );

export const useUpdateGivingFormReferenceCode = ({
  givingFormId,
  organizationId,
  referenceCode
}: UpdateGivingFormReferenceCodePostBody) =>
  useMutation(
    [
      'updateGivingFormReferenceCode',
      {
        givingFormId,
        organizationId,
        referenceCode
      }
    ],
    () =>
      updateGivingFormReferenceCode({
        givingFormId,
        organizationId,
        referenceCode
      })
  );

export const useUpdateGivingFormRedirectURL = () =>
  useMutation((request: UpdateGivingFormRedirectURLPostBody) =>
    updateGivingFormRedirectURL(request)
  );

export const useUpdateSmsForm = () =>
  useMutation((request: UpdateSmsFormPostBody) => updateSmsForm(request));

export const useUpdateBannerImages = ({
  givingFormId,
  organizationId,
  userId
}: Omit<UpdateBannerImagesPost, 'bannerImages'>) => {
  const abortControllerRef = useRef<AbortController | null>(null);

  const mutation = useMutation(
    [
      'updateBannerImages',
      {
        givingFormId,
        organizationId,
        userId
      }
    ],
    ({ bannerImages }: { bannerImages: BannerImages }) => {
      abortControllerRef.current = new AbortController();
      return updateBannerImages(
        {
          bannerImages,
          givingFormId,
          organizationId,
          userId
        },
        abortControllerRef.current.signal
      );
    }
  );

  const reset = useCallback(() => {
    abortControllerRef.current?.abort();
    mutation.reset();
  }, [mutation]);

  return { ...mutation, reset };
};
export const useDeleteBannerImages = ({
  givingFormId,
  organizationId,
  fileName,
  userId
}: any) =>
  useMutation(['draftBannerImages'], () =>
    deleteBannerImages({ givingFormId, organizationId, fileName, userId })
  );

export const useGetTemplateShares = (
  request: GetAllTemplateSharesRequest,
  options?: UseQueryOptions<TemplateShare[]>
) =>
  useQuery<TemplateShare[]>(
    ['getAllTemplateShares', request],
    () => getAllTemplateShares(request),
    options
  );

export const useUpdateTemplateShares = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (updateTemplateSharesDto: UpdateTemplateSharePostBody) =>
      updateTemplateShares(updateTemplateSharesDto),
    {
      onSuccess: (result: TemplateShare[]) => {
        result.forEach((share: TemplateShare) => {
          queryClient.invalidateQueries({
            queryKey: [
              'getGivingFormTemplates',
              {
                organizationId: share.sharedOrganizationId
              }
            ]
          });
        });
      }
    }
  );
};

export const useDeleteTemplateShare = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ['deleteTemplateShare'],
    (deleteTemplateShareDto: DeleteTemplateSharePostBody) =>
      deleteTemplateShare(deleteTemplateShareDto),
    {
      onSuccess: (result, deleteTemplateShareDto) => {
        queryClient.setQueryData(
          [
            'getGivingFormTemplates',
            {
              organizationId: deleteTemplateShareDto.sharedOrganizationId,
              includeGlobals: false,
              includeSharedTemplates: true
            }
          ],
          (prevState: GivingForm[] | undefined): GivingForm[] => {
            if (prevState) {
              return prevState.filter(
                (givingForm: GivingForm) =>
                  givingForm.embedInstanceId !==
                  deleteTemplateShareDto.templateId
              );
            }

            return [];
          }
        );
      }
    }
  );
};

export const useSaveImage = () =>
  useMutation((payload: UploadImagePost) => saveImage(payload));

export const useDeleteImage = () =>
  useMutation((payload: DeleteImage) => deleteImage(payload));

export const useGetGivingFormsForReceipt = ({
  organizationId,
  emailId
}: GetGivingFormsForEmail) =>
  useQuery(
    ['getGivingFormsForReceipt', { organizationId, emailId }],
    () =>
      getGivingFormsForEmailService({
        organizationId,
        emailId
      }),
    { enabled: !!organizationId && !!emailId }
  );

export const useArchiveGivingForm = (organizationId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      isArchived,
      givingFormId,
      isLegacy
    }: {
      isArchived: boolean;
      givingFormId: string;
      isLegacy: boolean;
    }) => {
      if (isLegacy) {
        return archiveLegacyEmbedService({
          givingFormId,
          organizationId,
          isArchived
        });
      }

      return archiveGivingFormService({
        givingFormId,
        organizationId,
        isArchived
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('campaignGivingForms');
      }
    }
  );
};
