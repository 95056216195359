import { AxiosError } from 'axios';
import axios from './axiosClient';
import { IOrganizationUserApiKey, IUser } from './types/userTypes';

export const getUserService = async (): Promise<IUser> => {
  try {
    const { data: user } = await axios.get(`/users/current`);
    return user;
  } catch (e) {
    throw Error(
      (e as AxiosError).response.data.message ||
        'There was an error fetching your form.'
    );
  }
};

export const getUserInfoById = async (
  userId: string,
  organizationId: string
): Promise<IUser> => {
  const { data } = await axios.get(
    `/users/${userId}?organizationId=${organizationId}`
  );
  return data;
};

export const getUserApiKeyById = async (
  userId: string,
  organizationId: string
): Promise<IOrganizationUserApiKey> => {
  const { data } = await axios.get(
    `/users/${userId}/api?organizationId=${organizationId}`
  );
  return data.result;
};

export const generateApiKeyForUserId = async (
  userId: string,
  organizationId: string
): Promise<IOrganizationUserApiKey> => {
  const { data } = await axios.post(
    `/users/${userId}/api?organizationId=${organizationId}`
  );
  return data;
};
