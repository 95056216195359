import { Stack } from '@mui/material';
import Dialog, {
  DialogActionButton,
  DialogActions,
  DialogContent,
  DialogTitle
} from 'components/Dialog';
import Text from 'components/Text';

export type UserDeleteConfirmationDialogProps = {
  open: boolean;
  onConfirm: () => void;
  onClose: () => void;
};

export const UserDeleteConfirmationDialog = ({
  open,
  onConfirm,
  onClose
}: UserDeleteConfirmationDialogProps) => (
  <Dialog
    className="user-delete-confirmation-dialog"
    label="Delete User Confirmation"
    open={open}
    onClose={onClose}
  >
    <DialogTitle onClick={(close) => close()} />
    <DialogContent>
      <Stack>
        <Text variant="h1" className="user-delete-confirmation-dialog-title">
          Delete Organization User
        </Text>
        <Text variant="body">
          Are you sure you want to remove this user from the organization?
        </Text>
      </Stack>
    </DialogContent>
    <DialogActions>
      <DialogActionButton onClick={onConfirm}>Yes, Delete</DialogActionButton>
      <DialogActionButton onClick={onClose}>No, Cancel</DialogActionButton>
    </DialogActions>
  </Dialog>
);
