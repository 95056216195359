import { useEffect } from 'react';
import { FormControl, Select as MuiSelect, styled } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { Blocker, ICONS, LimitedTextField } from 'components';
import Breadcrumbs from 'components/Breadcrumbs';
import Button from 'components/Button';
import IconButton from 'components/IconButton';
import { SelectMenuItem } from 'components/Select';
import Text from 'components/Text';
import TextField from 'components/TextField';
import { useAlerts } from 'hooks';
import { useAppContext } from 'hooks/useAppContext';
import {
  useCreateOrganizationUser,
  useUpdateOrganizationUser
} from 'queries/UseOrganizationData';
import {
  useGenerateUserApiKey,
  useGetUserApiKey,
  useGetUserInfo
} from 'queries/UseUsers';
import { IOrganizationUser, OrganizationUserSchema } from './User.schema';
import './UserEdit.scss';

export const UserEdit = () => {
  const { userId: userIdParam } = useParams();
  const {
    selectedOrganization: { id: organizationId }
  } = useAppContext();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [addAlert] = useAlerts();

  // Fetch user info if we have a userId
  const { data: userInfo, isFetching: isUserFetching } = useGetUserInfo(
    userIdParam,
    organizationId,
    {
      enabled: Boolean(userIdParam),
      refetchOnMount: 'always'
    }
  );

  const breadcrumbs = [
    {
      label: 'Users',
      path: '/users'
    },
    {
      label: userIdParam ? 'Edit User' : 'New User',
      path: ''
    }
  ];

  const styledChevronIcon = (disabled: boolean) =>
    styled(ICONS.CHEVRON_DOWN)(({ theme }) => ({
      '&': { top: 'unset' },
      stroke: disabled
        ? theme.palette.lightGray.main
        : theme.palette.darkGray.main
    }));

  const methods = useForm<IOrganizationUser>({
    resolver: yupResolver(OrganizationUserSchema),
    mode: 'onBlur'
  });

  const { data: userApiKey, isFetching: isApiKeyLoading } = useGetUserApiKey(
    userIdParam,
    organizationId,
    {
      enabled: Boolean(userIdParam)
    }
  );

  const { mutateAsync: generateApiKey, isLoading: isApiKeyGenerating } =
    useGenerateUserApiKey(userIdParam, organizationId);

  const { mutateAsync: createOrganizationUser, isLoading: isUserSaving } =
    useCreateOrganizationUser(organizationId);

  const { mutateAsync: updateOrganizationUser, isLoading: isUserUpdating } =
    useUpdateOrganizationUser(organizationId);

  const handleFormSubmit = async (formData: IOrganizationUser) => {
    const userObject = {
      email: formData.email,
      firstName: formData.firstName,
      middleName: formData.middleName,
      lastName: formData.lastName,
      phone: formData.phone,
      role: formData.role
    };

    if (userIdParam) {
      // we have an existing user, we're editing
      await updateOrganizationUser(userObject);
    } else if (formData.email && formData.role) {
      // we're creating a new user
      await createOrganizationUser(userObject);
    }

    // reset cached users
    await queryClient.invalidateQueries('organizationUsers');
    await queryClient.invalidateQueries('abTestNoteUserInfo');

    // navigate back to the users page
    navigate('/users');
  };

  const handleSubmitOnClick = () => {
    methods.handleSubmit((formData) => handleFormSubmit(formData))();
  };

  const handleGenerateApiKey = () => {
    generateApiKey().then(
      () => {
        queryClient.invalidateQueries('userApiKey');
      },
      (err) => {
        addAlert({
          title: 'API Key',
          description: 'API Key Generation failed.',
          severity: 'error'
        });
      }
    );
  };

  const handleCopyKeyToClipboard = () => {
    navigator.clipboard.writeText(userApiKey?.key).then(
      () => {
        addAlert({
          title: 'API Key',
          description: 'API Key has been copied to the clipboard.',
          severity: 'success'
        });
      },
      (err) => {
        addAlert({
          title: 'API Key',
          description: 'API Key failed to copy to the clipboard.',
          severity: 'error'
        });
      }
    );
  };

  useEffect(() => {
    // for edit screen
    if (userInfo) {
      methods.setValue('email', userInfo.email);
      methods.setValue('firstName', userInfo.firstName);
      methods.setValue('middleName', userInfo.middleName);
      methods.setValue('lastName', userInfo.lastName);
      methods.setValue('phone', userInfo.phone);
      methods.setValue('role', userInfo.role);
      // force dropdown re-render
      methods.trigger('role');
    }
  }, [userInfo]);

  return (
    <div className="user-edit">
      <div className="user-edit-header">
        <div className="user-edit-breadcrumbs">
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </div>
        <Text variant="h2" color="primary">
          {userIdParam ? 'Edit This' : 'Create A New'} User
        </Text>
      </div>
      <div className="user-edit-form">
        <Blocker
          block={
            isUserFetching ||
            isUserUpdating ||
            isUserSaving ||
            isApiKeyGenerating ||
            isApiKeyLoading
          }
        >
          <FormProvider {...methods}>
            <form
              className="user-edit-form-content"
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmitOnClick();
              }}
            >
              <div className="user-edit-form-row">
                <Text
                  className="user-edit-form-label center-label"
                  variant="h3"
                >
                  Email Address *
                </Text>
                <Controller
                  control={methods.control}
                  defaultValue=""
                  name="email"
                  render={({
                    field: { ref, ...field },
                    fieldState: { error }
                  }) => (
                    <LimitedTextField
                      {...field}
                      className="user-edit-form-field"
                      error={!!error}
                      helperText={error?.message ?? null}
                      hiddenLabel
                      maxChar={60}
                      disabled={!!userIdParam}
                      placeholder="Email Address"
                    />
                  )}
                />
              </div>
              <div className="user-edit-form-row">
                <Text
                  className="user-edit-form-label center-label"
                  variant="h3"
                >
                  First Name
                </Text>
                <Controller
                  control={methods.control}
                  defaultValue=""
                  name="firstName"
                  render={({
                    field: { ref, ...field },
                    fieldState: { error }
                  }) => (
                    <LimitedTextField
                      {...field}
                      className="user-edit-form-field"
                      error={!!error}
                      helperText={error?.message ?? null}
                      hiddenLabel
                      maxChar={60}
                      placeholder="First Name"
                    />
                  )}
                />
              </div>
              <div className="user-edit-form-row">
                <Text
                  className="user-edit-form-label center-label"
                  variant="h3"
                >
                  Middle Name
                </Text>
                <Controller
                  control={methods.control}
                  defaultValue=""
                  name="middleName"
                  render={({
                    field: { ref, ...field },
                    fieldState: { error }
                  }) => (
                    <LimitedTextField
                      {...field}
                      className="user-edit-form-field"
                      error={!!error}
                      helperText={error?.message ?? null}
                      hiddenLabel
                      maxChar={60}
                      placeholder="Middle Name"
                    />
                  )}
                />
              </div>
              <div className="user-edit-form-row">
                <Text
                  className="user-edit-form-label center-label"
                  variant="h3"
                >
                  Last Name
                </Text>
                <Controller
                  control={methods.control}
                  defaultValue=""
                  name="lastName"
                  render={({
                    field: { ref, ...field },
                    fieldState: { error }
                  }) => (
                    <LimitedTextField
                      {...field}
                      className="user-edit-form-field"
                      error={!!error}
                      helperText={error?.message ?? null}
                      hiddenLabel
                      maxChar={60}
                      placeholder="Last Name"
                    />
                  )}
                />
              </div>
              <div className="user-edit-form-row">
                <Text
                  className="user-edit-form-label center-label"
                  variant="h3"
                >
                  Phone Number
                </Text>
                <Controller
                  control={methods.control}
                  defaultValue=""
                  name="phone"
                  render={({
                    field: { ref, ...field },
                    fieldState: { error }
                  }) => (
                    <LimitedTextField
                      {...field}
                      type="tel"
                      className="user-edit-form-field"
                      error={!!error}
                      helperText={error?.message ?? null}
                      hiddenLabel
                      maxChar={60}
                      placeholder="Phone Number"
                    />
                  )}
                />
              </div>
              <div className="user-edit-form-row">
                <Text
                  className="user-edit-form-label center-label"
                  variant="h3"
                >
                  User Role *
                </Text>
                <Controller
                  control={methods.control}
                  defaultValue="Viewer"
                  name="role"
                  render={({
                    field: { ref, onChange: fieldChanged, value, ...field }
                  }) => (
                    <div className="user-edit-form-field">
                      <FormControl fullWidth>
                        <MuiSelect
                          {...field}
                          IconComponent={styledChevronIcon(false)}
                          value={value}
                          onChange={(e) => fieldChanged(e.target.value)}
                        >
                          {[
                            { label: 'Admin', value: 'Admin' },
                            { label: 'Editor', value: 'Editor' },
                            { label: 'Viewer', value: 'Viewer' }
                          ].map(SelectMenuItem)}
                        </MuiSelect>
                      </FormControl>
                    </div>
                  )}
                />
              </div>
              {userIdParam && (
                <div className="user-edit-form-row">
                  <Text
                    className="user-edit-form-label center-label"
                    variant="h3"
                  >
                    API Key
                  </Text>
                  <div className="user-edit-form-field">
                    <div className="key-display">
                      <TextField
                        fullWidth
                        value={userApiKey?.key}
                        InputProps={{
                          endAdornment: (
                            <IconButton
                              label="Copy API Key"
                              icon={ICONS.COPY}
                              onClick={handleCopyKeyToClipboard}
                            />
                          )
                        }}
                      />
                    </div>
                    <div className="key-generate">
                      <Button onClick={handleGenerateApiKey}>
                        Generate API Key
                      </Button>
                    </div>
                  </div>
                </div>
              )}
              <div className="user-edit-form-submit-button-container">
                <Button
                  fullWidth
                  type="submit"
                  disabled={!methods.formState.isValid}
                >
                  Save User
                </Button>
              </div>
            </form>
          </FormProvider>
        </Blocker>
      </div>
    </div>
  );
};
