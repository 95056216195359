import * as yup from 'yup';

export interface IOrganizationUser {
  organizationId?: string;
  id?: string;
  email: string;
  firstName: string;
  middleName: string;
  lastName: string;
  role: string;
  phone: string;
}

export const OrganizationUserSchema = yup.object({
  email: yup
    .string()
    .email('Email Address must be valid')
    .required('Email Address is required'),
  role: yup.string().required('Role must be assigned')
});
