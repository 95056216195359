import { UseQueryOptions, useMutation, useQuery } from 'react-query';
import { IOrganizationUserApiKey, IUser } from 'services/types/userTypes';
import {
  generateApiKeyForUserId,
  getUserApiKeyById,
  getUserInfoById,
  getUserService
} from '../services/userService';

export const useUser = (onSuccess?: (data: IUser) => void) =>
  useQuery(['getUser'], () => getUserService(), {
    onSuccess
  });

export const useGetUserInfo = (
  userId: string,
  organizationId: string,
  queryOptions?: UseQueryOptions<IUser>
) =>
  useQuery<IUser>(
    ['abTestNoteUserInfo', userId],
    () => getUserInfoById(userId, organizationId),
    queryOptions
  );

export const useGetUserApiKey = (
  userId: string,
  organizationId: string,
  queryOptions?: UseQueryOptions<IOrganizationUserApiKey>
) =>
  useQuery<IOrganizationUserApiKey>(
    ['userApiKey', userId],
    () => getUserApiKeyById(userId, organizationId),
    queryOptions
  );

export const useGenerateUserApiKey = (userId: string, organizationId: string) =>
  useMutation(['generateUserApiKey', { userId, organizationId }], () =>
    generateApiKeyForUserId(userId, organizationId)
  );
