import Dialog, {
  DialogActionButton,
  DialogActions,
  DialogContent,
  DialogContentDescription,
  DialogContentTitle,
  DialogTitle
} from 'components/Dialog';
import './ArchiveCampaignOrChannelModal.scss';

export enum ArchiveType {
  CAMPAGIN = 'Campaign',
  CHANNEL = 'Channel'
}

export enum ArchiveSubtype {
  GIVING_FORM = 'Giving Form',
  EMBED = 'Embed',
  P2P_PROGRAM = 'Peer-to-Peer Program',
  EVENT = 'Event',
  KEYWORD = 'Keyword'
}

type ArchiveCampaignOrChannelModalProps = {
  onConfirm: () => void;
  onClose: () => void;
  open: boolean;
  subtype: ArchiveSubtype;
  type: ArchiveType;
};

export const ArchiveCampaignOrChannelModal = ({
  onConfirm,
  onClose,
  open,
  subtype,
  type
}: ArchiveCampaignOrChannelModalProps) => {
  const mapArchiveType = () => {
    if (type === ArchiveType.CAMPAGIN) {
      return 'campaign';
    }

    if (subtype === ArchiveSubtype.EMBED) {
      return ArchiveSubtype.GIVING_FORM;
    }

    return subtype;
  };

  const dialogContent =
    type === ArchiveType.CAMPAGIN
      ? 'Once archived, the campaign will no longer be active and will move to the archived campaigns list.'
      : `The ${mapArchiveType()} will be removed from your active channel view and placed at the bottom of the Campaign home page.`;

  return (
    <Dialog open={open} label="Archive Modal" onClose={onClose}>
      <DialogTitle
        onClick={(close) => close()}
        className="archive-campaign-modal-title"
      >
        {type === ArchiveType.CAMPAGIN
          ? 'Archive Campaign'
          : `Archive ${mapArchiveType()}`}
      </DialogTitle>
      <DialogContent>
        <DialogContentTitle>
          Are you sure you want to archive this {mapArchiveType()}?
        </DialogContentTitle>
        <DialogContentDescription>{dialogContent}</DialogContentDescription>
      </DialogContent>
      <DialogActions className="archive-campaign-modal-title-action-container">
        <DialogActionButton
          onClick={(close) => {
            onConfirm();
            close();
          }}
          variant="primary"
        >
          Yes, Archive
        </DialogActionButton>
        <DialogActionButton onClick={(close) => close()} variant="secondary">
          No, Cancel
        </DialogActionButton>
      </DialogActions>
    </Dialog>
  );
};
