import { UseQueryOptions, useMutation, useQuery } from 'react-query';
import {
  createOrganizationUser,
  deleteOrganizationUser,
  getOrgGivingForms,
  getOrgRevenueByChannelService,
  getOrganizationDashboardService,
  getOrganizationPaymentGateways,
  getOrganizationReferenceCodes,
  getOrganizationService,
  getOrganizationUsers,
  updateOrganizationUser
} from 'services/organizationService';
import {
  IOrganizationDashboard,
  IOrganizationInfo,
  IPaymentGateway,
  OrgGivingForms,
  OrgRevenueByChannel,
  PlotBy,
  ReferenceCodes
} from 'services/types';
import { IOrganizationUser } from 'views/Users/User.schema';

export const useOrganization = (
  orgId: string,
  useQueryOptions?: UseQueryOptions<IOrganizationInfo, Error>
) =>
  useQuery<IOrganizationInfo, Error>(
    ['getOrganization'],
    () => getOrganizationService(orgId),
    { ...useQueryOptions, enabled: !!orgId }
  );

export const useOrganizationDashboard = (
  orgId: string,
  startDate: string,
  endDate: string,
  plotBy: PlotBy,
  includeChapters: boolean,
  allTimeRangeSelected: boolean,
  useQueryOptions?: UseQueryOptions<IOrganizationDashboard, Error>
) =>
  useQuery<IOrganizationDashboard, Error>(
    [
      'getOrganizationDashboard',
      {
        orgId,
        startDate,
        endDate,
        plotBy,
        includeChapters,
        allTimeRangeSelected
      }
    ],
    () =>
      getOrganizationDashboardService(
        orgId,
        startDate,
        endDate,
        plotBy,
        includeChapters,
        allTimeRangeSelected
      ),
    useQueryOptions
  );

export const useOrgRevenueByChannel = (
  orgId: string,
  startDate: string,
  endDate: string,
  useQueryOptions?: UseQueryOptions<OrgRevenueByChannel, Error>
) =>
  useQuery<OrgRevenueByChannel, Error>(
    ['getOrganizationDashboard', { orgId, startDate, endDate }],
    () => getOrgRevenueByChannelService(orgId, startDate, endDate),
    useQueryOptions
  );

export const useOrgGivingForms = (
  organizationId: string,
  useQueryOptions?: UseQueryOptions<OrgGivingForms, Error>
) =>
  useQuery<OrgGivingForms, Error>(
    ['getOrgGivingForms', { organizationId }],
    () => getOrgGivingForms(organizationId),
    useQueryOptions
  );

export const useOrganizationReferenceCodes = (
  organizationId: string,
  useQueryOptions?: UseQueryOptions<ReferenceCodes, Error>
) =>
  useQuery<ReferenceCodes, Error>(
    ['getOrganizationReferenceCodes', { organizationId }],
    () => getOrganizationReferenceCodes(organizationId),
    useQueryOptions
  );

export const useOrganizationPaymentGateways = (
  organizationId: string,
  useQueryOptions?: UseQueryOptions<IPaymentGateway[], Error>
) =>
  useQuery<IPaymentGateway[], Error>(
    ['getOrganizationPaymentGateways', { organizationId }],
    () => getOrganizationPaymentGateways(organizationId),
    useQueryOptions
  );

export const useOrganizationUsers = (
  organizationId: string,
  queryOptions?: UseQueryOptions<IOrganizationUser[]>
) =>
  useQuery<IOrganizationUser[]>(
    ['organizationUsers', organizationId],
    () => getOrganizationUsers(organizationId),
    queryOptions
  );

export const useCreateOrganizationUser = (
  organizationId: string,
  queryOptions?: UseQueryOptions<IOrganizationUser>
) =>
  useMutation(
    ['createOrganizationUser', { organizationId }],
    (orgUser: IOrganizationUser) =>
      createOrganizationUser({
        organizationId,
        email: orgUser.email,
        firstName: orgUser.firstName,
        middleName: orgUser.middleName,
        lastName: orgUser.lastName,
        phone: orgUser.phone,
        role: orgUser.role
      }),
    queryOptions
  );

export const useUpdateOrganizationUser = (
  organizationId: string,
  queryOptions?: UseQueryOptions<IOrganizationUser>
) =>
  useMutation(
    ['updateOrganizationUser', { organizationId }],
    (orgUser: IOrganizationUser) =>
      updateOrganizationUser({
        organizationId,
        email: orgUser.email,
        firstName: orgUser.firstName,
        middleName: orgUser.middleName,
        lastName: orgUser.lastName,
        phone: orgUser.phone,
        role: orgUser.role
      }),
    queryOptions
  );

export const useDeleteOrganizationUser = (
  organizationId: string,
  queryOptions?: UseQueryOptions<boolean>
) =>
  useMutation(
    ['deleteOrganizationUser', { organizationId }],
    (userId: string) => deleteOrganizationUser(organizationId, userId),
    queryOptions
  );
