import { useMemo, useState } from 'react';
import { Box, InputAdornment, TableCell } from '@mui/material';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { Blocker } from 'components/Blocker';
import { ButtonProps } from 'components/Button';
import Icon, { ICONS } from 'components/Icon';
import IconButton from 'components/IconButton';
import { Table, TableHeaderProps } from 'components/Table';
import TextField from 'components/TextField';
import { FeatureHeader } from 'components/gms/FeatureHeader';
import { useAppContext } from 'hooks/useAppContext';
import {
  useDeleteOrganizationUser,
  useOrganizationUsers
} from 'queries/UseOrganizationData';
import { IOrganizationUser } from './User.schema';
import { UserDeleteConfirmationDialog } from './UserDeleteConfirmationDialog';
import './Users.scss';

export const Users = () => {
  const [searchQuery, setSearchQuery] = useState<string>();
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
  const [userToDelete, setUserToDelete] = useState<IOrganizationUser>();

  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    selectedOrganization: { id: organizationId }
  } = useAppContext();

  const { data: users, isFetching: isUsersLoading } =
    useOrganizationUsers(organizationId);
  const { mutateAsync: deleteOrganizationUser, isLoading: isUserDeleting } =
    useDeleteOrganizationUser(organizationId);

  const mappedUsers: IOrganizationUser[] = useMemo(() => {
    if (!users) {
      return [];
    }

    return users.map(
      (u: any) =>
        ({
          id: u.id,
          firstName: u.contact.firstname,
          middleName: u.contact.middlename,
          lastName: u.contact.lastname,
          phone: u.contact.phone,
          email: u.email,
          role: u.role
        } as IOrganizationUser)
    );
  }, [users]);

  const buttonProps: ButtonProps = {
    children: 'New User',
    startIcon: <Icon icon={ICONS.PLUS} />,
    onClick: () => navigate('/users/new')
  };

  const handleEdit = (user: IOrganizationUser) => {
    navigate(`/users/${user.id}`, {
      state: {
        user
      }
    });
  };

  const handleDelete = async (user: IOrganizationUser) => {
    setUserToDelete(user);
    setDeleteOpen(true);
  };

  const deleteUser = async () => {
    await deleteOrganizationUser(userToDelete.id);
    queryClient.invalidateQueries('organizationUsers');
    setDeleteOpen(false);
    setUserToDelete(null);
  };

  const tableHeaders: TableHeaderProps[] = [
    {
      key: 'edit',
      label: '',
      renderer: (value, user) => (
        <TableCell className="action-button">
          <IconButton
            variant="basic"
            label="Edit"
            tooltipLabel="Edit User"
            icon={ICONS.PENCIL}
            onClick={() => handleEdit(user)}
          />
          <IconButton
            variant="basic"
            label="Delete"
            tooltipLabel="Delete User"
            icon={ICONS.TRASH}
            onClick={() => handleDelete(user)}
          />
        </TableCell>
      )
    },
    {
      key: 'firstName',
      label: 'First Name'
    },
    {
      key: 'middleName',
      label: 'Middle Name'
    },
    {
      key: 'lastName',
      label: 'Last Name'
    },
    {
      key: 'email',
      label: 'Email'
    },
    {
      key: 'role',
      label: 'Role'
    }
  ];

  const filteredUsers = mappedUsers?.filter((u) => {
    if (!searchQuery) {
      return true;
    }

    return (
      u.firstName?.toLowerCase()?.includes(searchQuery.toLowerCase()) ||
      u.middleName?.toLowerCase()?.includes(searchQuery.toLowerCase()) ||
      u.lastName?.toLowerCase()?.includes(searchQuery.toLowerCase()) ||
      u.email?.toLowerCase()?.includes(searchQuery.toLowerCase()) ||
      u.phone?.toLowerCase()?.includes(searchQuery.toLowerCase())
    );
  });

  return (
    <div className="users">
      <FeatureHeader primaryButtonProps={buttonProps} titleProps="Users" />
      <Box padding={7} paddingTop={11} display="flex" flexDirection="column">
        <Box className="users-wrapper">
          <TextField
            className="list-header-search-bar"
            placeholder="Search Users"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon icon={ICONS.SEARCH} />
                </InputAdornment>
              )
            }}
            autoComplete="off"
          />
        </Box>
        <Blocker block={isUsersLoading || isUserDeleting}>
          <Table
            headers={tableHeaders}
            rows={filteredUsers}
            isLoading={isUsersLoading || isUserDeleting}
          />
        </Blocker>
        <UserDeleteConfirmationDialog
          open={deleteOpen}
          onConfirm={() => {
            deleteUser();
          }}
          onClose={() => {
            setDeleteOpen(false);
            setUserToDelete(null);
          }}
        />
      </Box>
    </div>
  );
};
