import { ReactNode, useState } from 'react';
import { Divider, FormControlLabel, SelectChangeEvent } from '@mui/material';
import { MultiselectCheckbox } from 'components';
import Checkbox from 'components/Checkbox';
import ComboBox from 'components/ComboBox';
import { MultiselectOption } from 'components/Select/MultiselectCheckbox';
import Text from 'components/Text';
import { LimitedTextField } from 'components/TextField';
import { TransactionFeeMessage } from 'components/gms/TransactionFeeMessage';
import { useAlerts } from 'hooks';
import { useAppContext } from 'hooks/useAppContext';
import { useConfigContext } from 'hooks/useConfigContext';
import { useOrganizationPaymentGateways } from 'queries/UseOrganizationData';
import { IPaymentGateway } from 'services/types';
import {
  AllPaymentOptions,
  BlockTypes,
  IBillingInfoBlock,
  IGiveAnonymouslyBlock,
  IGivingFormConfig,
  IPaymentInfoSection
} from 'types';
import { ICoverTransactionFeeBlock } from 'types/givingFormTypes/CoverTransactionFeeBlock';
import {
  BlockDetailType,
  DonorGiftOptionsType,
  IThankYouMessageDonationDetailsBlock
} from 'types/givingFormTypes/ThankYouMessageDonationDetailsBlock';
import { reactJoin } from 'utils';
import prefixes from 'utils/prefixes.json';
import OptionalSection from '../OptionalSection';
import './EditPaymentOptions.scss';

export const PAYMENT_OPTIONS_MAP: Record<string, string> = {
  paypal: 'PAYPAL',
  apple_pay: 'APPLEPAY',
  google_pay: 'GOOGLEPAY',
  echecks: 'ECHECK',
  creditcards: 'CREDIT',
  debitcards: 'DEBIT'
};

const getAllowedPaymentOptions = (
  acceptedPaymentOptions?: string[]
): MultiselectOption[] =>
  acceptedPaymentOptions
    ?.map((option) => PAYMENT_OPTIONS_MAP[option])
    .map((value) => ({
      label: value,
      value
    }));

const EditPaymentOptions = (): JSX.Element => {
  const {
    configData: { config },
    updateConfig
  } = useConfigContext<IGivingFormConfig>();
  const { organizationInfo, selectedOrganization } = useAppContext();
  const [addAlert] = useAlerts();

  const { data: paymentGateways, isLoading: fetchingPaymentGateways } =
    useOrganizationPaymentGateways(selectedOrganization.id, {
      enabled: organizationInfo?.features?.overridePaymentGateway
    });

  const initialPaymentSection = config.blocks.find(
    (block) => block.blockType === 'PaymentSection'
  ) as IPaymentInfoSection;

  const allowedPaymentOptions = getAllowedPaymentOptions(
    organizationInfo?.acceptedPaymentOptions
  );
  const initialPaymentOptions =
    initialPaymentSection?.paymentOptionsBlock.paymentOptions.filter(
      (initialOption) =>
        allowedPaymentOptions.find(
          (allowedOption) => allowedOption.value === initialOption
        )
    );

  const {
    enablePrefix,
    selectedPrefixes,
    enableMiddleName,
    enableCompanyName,
    enablePhone
  } = initialPaymentSection?.billingInfoBlock as IBillingInfoBlock;

  const initialCoverTransactionFeeBlock = config.blocks.find(
    (block) => block.blockType === 'CoverTransactionFeeBlock'
  ) as ICoverTransactionFeeBlock;

  const initialGiveAnonymouslyBlock = config.blocks.find(
    (block) => block.blockType === 'GiveAnonymouslyBlock'
  ) as IGiveAnonymouslyBlock;
  const [giveAnonymouslyMessage, setGiveAnonymouslyMessage] = useState<string>(
    initialGiveAnonymouslyBlock?.message
  );

  const [coverTransactionFeeSelected, setCoverTransactionFeeSelected] =
    useState(!!initialCoverTransactionFeeBlock);
  const [giveAnonymouslySelected, setGiveAnonymouslySelected] = useState(
    !!initialGiveAnonymouslyBlock
  );

  const handlePaymentOptionsChange = (event: SelectChangeEvent<string[]>) => {
    const newConfig = { ...config };
    let values = event.target.value as AllPaymentOptions[];
    if (typeof event.target.value === 'string') {
      values = [event.target.value] as AllPaymentOptions[];
    }

    const paymentSection = newConfig.blocks.find(
      (block) => block.blockType === 'PaymentSection'
    ) as IPaymentInfoSection;

    if (paymentSection) {
      paymentSection.paymentOptionsBlock.paymentOptions = values;
    } else {
      const newPaymentOptionsBlock: IPaymentInfoSection = {
        blockType: BlockTypes.PaymentSection,
        id: '01-paymentSection-fromGmsReact',
        paymentOptionsBlock: {
          paymentOptions: values
        },
        billingInfoBlock: {
          enablePhone: true,
          enableCompanyName: true,
          enablePrefix: true,
          enableMiddleName: true,
          enableInternationalDonations: true
        },
        ccPaymentProvider: ''
      };
      newConfig.blocks.push(newPaymentOptionsBlock);
    }
    updateConfig(newConfig);
  };

  const handlePaymentGatewayOptionsChange = (value: IPaymentGateway) => {
    const newConfig = { ...config };

    const paymentSection = newConfig.blocks.find(
      (block) => block.blockType === 'PaymentSection'
    ) as IPaymentInfoSection;

    if (paymentSection) {
      paymentSection.paymentGateway = value;
    } else {
      const newPaymentOptionsBlock: IPaymentInfoSection = {
        blockType: BlockTypes.PaymentSection,
        id: '01-paymentSection-fromGmsReact',
        paymentOptionsBlock: {
          paymentOptions: []
        },
        paymentGateway: value,
        billingInfoBlock: {
          enablePhone: true,
          enableCompanyName: true,
          enablePrefix: true,
          enableMiddleName: true,
          enableInternationalDonations: true
        },
        ccPaymentProvider: ''
      };
      newConfig.blocks.push(newPaymentOptionsBlock);
    }
    updateConfig(newConfig);
  };

  const enableDonorInfoField = (
    field: keyof IBillingInfoBlock,
    enabled: boolean
  ) => {
    if (field === 'selectedPrefixes') {
      return;
    }

    const newConfig = { ...config };

    const { billingInfoBlock } = newConfig.blocks.find(
      (block) => block.blockType === 'PaymentSection'
    ) as IPaymentInfoSection;

    if (billingInfoBlock) {
      billingInfoBlock[field] = enabled;
    } else {
      const newPaymentOptionsBlock: IPaymentInfoSection = {
        blockType: BlockTypes.PaymentSection,
        id: '01-paymentSection-fromGmsReact',
        paymentOptionsBlock: {
          paymentOptions: []
        },
        // if block does not exist when toggle first selected,
        // then all toggles will initally be deselected.
        billingInfoBlock: {
          enablePhone: false,
          enableCompanyName: false,
          enablePrefix: false,
          enableMiddleName: false,
          enableInternationalDonations: false
        },
        ccPaymentProvider: ''
      };
      // after initiating all billingInfo fields to false,
      // updating the selected field to be toggled on
      newPaymentOptionsBlock.billingInfoBlock[field] = enabled;
      newConfig.blocks.push(newPaymentOptionsBlock);
    }

    updateConfig(newConfig);
  };

  const handleSelectedPrefixUpdate = (updatedPrefixes: string[]) => {
    const newConfig = { ...config };

    const { billingInfoBlock } = newConfig.blocks.find(
      (block) => block.blockType === 'PaymentSection'
    ) as IPaymentInfoSection;

    if (billingInfoBlock) {
      billingInfoBlock.selectedPrefixes = updatedPrefixes;
    } else {
      const newPaymentOptionsBlock: IPaymentInfoSection = {
        blockType: BlockTypes.PaymentSection,
        id: '01-paymentSection-fromGmsReact',
        paymentOptionsBlock: {
          paymentOptions: []
        },
        // if block does not exist when toggle first selected,
        // then all toggles will initally be deselected.
        billingInfoBlock: {
          enablePhone: false,
          enableCompanyName: false,
          enablePrefix: false,
          selectedPrefixes: updatedPrefixes,
          enableMiddleName: false,
          enableInternationalDonations: false
        },
        ccPaymentProvider: ''
      };

      newConfig.blocks.push(newPaymentOptionsBlock);
    }

    updateConfig(newConfig);
  };

  const enableCoverTransactionFeeSection = (enabled: boolean) => {
    const newConfig = { ...config };
    if (enabled) {
      const newCoverTransactionFeeBlock: ICoverTransactionFeeBlock = {
        blockType: BlockTypes.CoverTransactionFeeBlock,
        id: '01-cover-transaction-fee'
      };
      newConfig.blocks.push(newCoverTransactionFeeBlock);
    } else {
      newConfig.blocks = newConfig.blocks.filter(
        (block) => block.blockType !== 'CoverTransactionFeeBlock'
      );

      // Check if "Transaction Fee" is selected to display in the Thank You Donor/Gift Detail
      // and remove it if present:
      const tyDetailsDonorInfoBlock: IThankYouMessageDonationDetailsBlock =
        newConfig.thankYouConfig.blocks.find(
          (block) =>
            block.blockType ===
              BlockTypes.ThankYouMessageDonationDetailsBlock &&
            (block as IThankYouMessageDonationDetailsBlock).detailType ===
              BlockDetailType.DonorInformation
        ) as IThankYouMessageDonationDetailsBlock;
      const placeholders =
        tyDetailsDonorInfoBlock?.placeholders as DonorGiftOptionsType[];
      if (placeholders?.includes(DonorGiftOptionsType.TransactionFee)) {
        addAlert({
          title: 'Thank You Message Updated',
          description:
            'Because you disabled the transaction fee, your Transaction Fee selection has been removed from the Donor Info Thank You Message section.'
        });
        placeholders.splice(
          placeholders.indexOf(DonorGiftOptionsType.TransactionFee),
          1
        );
        if (placeholders.length === 0) {
          newConfig.thankYouConfig.blocks.splice(
            newConfig.thankYouConfig.blocks.indexOf(tyDetailsDonorInfoBlock),
            1
          );
        }
      }
    }

    updateConfig(newConfig);
  };

  const enableAnonymousGiftSection = (enabled: boolean) => {
    const newConfig = { ...config };
    if (enabled) {
      const newGiveAnonymouslyBlock: IGiveAnonymouslyBlock = {
        blockType: BlockTypes.GiveAnonymouslyBlock,
        id: '01-give-anonymously'
      };
      newConfig.blocks.push(newGiveAnonymouslyBlock);
    } else {
      newConfig.blocks = newConfig.blocks.filter(
        (block) => block.blockType !== 'GiveAnonymouslyBlock'
      );
      setGiveAnonymouslyMessage('');

      // Check if "Anonymous Gift" is selected to display in the Thank You Donor/Gift Detail
      // and remove it if present:
      const tyDetailsDonorInfoBlock: IThankYouMessageDonationDetailsBlock =
        newConfig.thankYouConfig.blocks.find(
          (block) =>
            block.blockType ===
              BlockTypes.ThankYouMessageDonationDetailsBlock &&
            (block as IThankYouMessageDonationDetailsBlock).detailType ===
              BlockDetailType.DonorInformation
        ) as IThankYouMessageDonationDetailsBlock;
      const placeholders =
        tyDetailsDonorInfoBlock?.placeholders as DonorGiftOptionsType[];
      if (placeholders?.includes(DonorGiftOptionsType.AnonymousGift)) {
        addAlert({
          title: 'Thank You Message Updated',
          description:
            'Because you disabled the anonymous gift option, your Anonymous Gift selection has been removed from the Donor Info Thank You Message section.'
        });
        placeholders.splice(
          placeholders.indexOf(DonorGiftOptionsType.AnonymousGift),
          1
        );
        if (placeholders.length === 0) {
          newConfig.thankYouConfig.blocks.splice(
            newConfig.thankYouConfig.blocks.indexOf(tyDetailsDonorInfoBlock),
            1
          );
        }
      }
    }

    updateConfig(newConfig);
  };

  const updateTransactionFeeMessage = (message: string) => {
    const newConfig = { ...config };
    const coverTransactionFeeBlock = newConfig.blocks.find(
      (block) => block.blockType === 'CoverTransactionFeeBlock'
    ) as ICoverTransactionFeeBlock;
    coverTransactionFeeBlock.message = message;
    updateConfig(newConfig);
  };

  const updateTransactionFeeDefaultSelected = (checkedByDefault: boolean) => {
    const newConfig = { ...config };
    const coverTransactionFeeBlock = newConfig.blocks.find(
      (block) => block.blockType === 'CoverTransactionFeeBlock'
    ) as ICoverTransactionFeeBlock;
    coverTransactionFeeBlock.checkedByDefault = checkedByDefault;
    updateConfig(newConfig);
  };

  const updateGiveAnonymouslyMessage = (message: string) => {
    const newConfig = { ...config };
    const giveAnonymouslyBlock = newConfig.blocks.find(
      (block) => block.blockType === 'GiveAnonymouslyBlock'
    ) as IGiveAnonymouslyBlock;
    giveAnonymouslyBlock.message = message;
    updateConfig(newConfig);
  };

  const withSeparator = (content: ReactNode[]) =>
    reactJoin(content, <Divider className="edit-payment-info__separator" />);

  const getAllPrefixOptions = () => {
    const allPrefixOptions = [].concat(
      prefixes.defaultPrefixes,
      prefixes.additionalPrefixes
    );
    return allPrefixOptions.map(
      (prefix) =>
        ({
          label: prefix,
          value: prefix
        } as MultiselectOption)
    );
  };

  const getSelectedPrefixOptions = () =>
    selectedPrefixes || prefixes.defaultPrefixes;

  return (
    <div className="edit-payment-info">
      {withSeparator([
        <div>
          <Text className="edit-payment-info__title" variant="h3">
            Payment Options
          </Text>
          <MultiselectCheckbox
            label="Select"
            options={allowedPaymentOptions}
            defaultValue={initialPaymentOptions}
            sortSelected
            onChange={handlePaymentOptionsChange}
          />
        </div>,
        ...(organizationInfo?.features?.overridePaymentGateway
          ? [
              <div>
                <Text className="edit-payment-info__title" variant="h3">
                  Payment Gateways
                </Text>
                <ComboBox
                  options={paymentGateways ?? []}
                  defaultValue={initialPaymentSection.paymentGateway}
                  getOptionLabel={(option) => option.name}
                  loading={fetchingPaymentGateways}
                  onChange={(e, v) =>
                    handlePaymentGatewayOptionsChange(v as IPaymentGateway)
                  }
                  isOptionEqualToValue={(o, v) => o.id === v.id}
                  renderOption={(props, option) => (
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  )}
                />
              </div>
            ]
          : []),
        <div>
          <Text className="edit-payment-info__title" variant="h3">
            Donor Information
          </Text>
          {withSeparator([
            <OptionalSection
              initiallySelected={enablePrefix}
              title="Prefix"
              titleVariant="h5"
              onChange={(enabled) => {
                enableDonorInfoField('enablePrefix', enabled);
              }}
            >
              <MultiselectCheckbox
                className="prefix-multi-select-dropdown"
                options={getAllPrefixOptions()}
                defaultValue={getSelectedPrefixOptions()}
                onChange={(evnt) => {
                  handleSelectedPrefixUpdate(evnt.target.value as string[]);
                }}
              />
            </OptionalSection>,
            <OptionalSection
              initiallySelected={enableMiddleName}
              title="Middle Name"
              titleVariant="h5"
              onChange={(enabled) => {
                enableDonorInfoField('enableMiddleName', enabled);
              }}
            />,
            <OptionalSection
              initiallySelected={enableCompanyName}
              title="On Behalf of Organization"
              titleVariant="h5"
              onChange={(enabled) => {
                enableDonorInfoField('enableCompanyName', enabled);
              }}
            />,
            <OptionalSection
              initiallySelected={enablePhone}
              title="Phone Number"
              titleVariant="h5"
              onChange={(enabled) => {
                enableDonorInfoField('enablePhone', enabled);
              }}
            />
          ])}
        </div>,

        <div>
          <Text className="edit-payment-info__title" variant="h3">
            Advanced Payment Options
          </Text>
          {withSeparator([
            <OptionalSection
              unmountOnExit
              initiallySelected={coverTransactionFeeSelected}
              title="Allow Donor to Pay Transaction Fee"
              titleVariant="h4"
              onChange={(enabled) => {
                enableCoverTransactionFeeSection(enabled);
                setCoverTransactionFeeSelected(!coverTransactionFeeSelected);
              }}
            >
              <Text className="edit-payment-info__field-label" variant="h5">
                Transaction Fee Message
              </Text>
              <TransactionFeeMessage
                defaultText={
                  initialCoverTransactionFeeBlock?.message ??
                  'I would like to cover the processing fee of {{Fee Amount}}.'
                }
                onBlur={(value) => {
                  updateTransactionFeeMessage(value);
                }}
                placeholder="{{Fee Amount}}"
              />
              <FormControlLabel
                defaultChecked={
                  !!initialCoverTransactionFeeBlock?.checkedByDefault
                }
                checked={!!initialCoverTransactionFeeBlock?.checkedByDefault}
                className="transaction-fee-checked-by-default"
                control={<Checkbox />}
                onChange={(e) =>
                  updateTransactionFeeDefaultSelected(
                    (e.target as HTMLInputElement).checked
                  )
                }
                label={
                  <Text variant="body">Automatically select for donor</Text>
                }
              />
            </OptionalSection>,
            <OptionalSection
              unmountOnExit
              initiallySelected={giveAnonymouslySelected}
              title="Allow Anonymous Donation"
              titleVariant="h4"
              onChange={(enabled) => {
                enableAnonymousGiftSection(enabled);
                setGiveAnonymouslySelected(!giveAnonymouslySelected);
              }}
            >
              <Text className="edit-payment-info__field-label" variant="h5">
                Anonymous Donation Message
              </Text>
              <LimitedTextField
                maxChar={100}
                minRows={3}
                multiline
                className="give-anonymously-textarea"
                placeholder="I would like to give my donation anonymously"
                onChange={(e) => setGiveAnonymouslyMessage(e.target.value)}
                onBlur={(e) => {
                  updateGiveAnonymouslyMessage(e.target.value);
                }}
                value={giveAnonymouslyMessage}
              />
            </OptionalSection>
          ])}
        </div>
      ])}
    </div>
  );
};

export default EditPaymentOptions;
