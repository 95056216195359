import { useEffect, useMemo, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack
} from '@mui/material';
import { ChartData, ChartOptions } from 'chart.js';
import 'chart.js/auto';
import pluralize from 'pluralize';
import { Doughnut } from 'react-chartjs-2';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Blocker } from 'components/Blocker';
import { BreadcrumbsProps } from 'components/Breadcrumbs';
import Button, { ButtonProps } from 'components/Button';
import Icon, { ICONS } from 'components/Icon';
import { MenuItemProps } from 'components/Menu';
import { Preview } from 'components/PreviewModal';
import Select from 'components/Select';
import Text from 'components/Text';
import {
  ArchiveCampaignOrChannelModal,
  ArchiveSubtype,
  ArchiveType
} from 'components/gms/ArchiveCampaignOrChannelModal';
import CampaignDetail from 'components/gms/CampaignDetail';
import { CampaignNotifications } from 'components/gms/CampaignNotifications';
import {
  EventPreviewCard,
  GivingFormPreviewCard,
  PeerToPeerPreviewCard,
  TextToGivePreviewCard
} from 'components/gms/ChannelPreviewCards';
import { ChannelPreviewGrid } from 'components/gms/ChannelPreviewGrid';
import ChannelSelectDialog from 'components/gms/ChannelSelectDialog';
import { CopyEmbedDialog } from 'components/gms/CopyEmbedDialog';
import { FeatureHeader } from 'components/gms/FeatureHeader';
import { useAlerts } from 'hooks/useAlerts/useAlerts';
import { useAppContext } from 'hooks/useAppContext';
import {
  OrganizationRole,
  getRoleInt,
  useRenderIfRole
} from 'hooks/useRenderIfRole';
import { useGetAllAbTests } from 'queries/UseAbTests';
import {
  useCampaignById,
  useCampaignEvents,
  useCampaignGivingForms,
  useCampaignMetrics,
  useCampaignPeerToPeers,
  useCampaignTextToGive,
  usePerChannelMetrics,
  useUpdateCampaign
} from 'queries/UseCampaigns';
import { useArchiveEvent } from 'queries/UseEvents';
import { useArchiveGivingForm } from 'queries/UseGivingForms';
import { useArchiveP2P } from 'queries/UseP2P';
import { useUser } from 'queries/UseUsers';
import editsRepositoryApi from 'services/editsRepositoryService';
import {
  CampaignEvent,
  CampaignGivingForm,
  CampaignMetrics,
  CampaignPeerToPeer,
  CampaignTextToGive,
  ChannelSummaryBase
} from 'services/types/campaignTypes';
import { AbTest } from 'types';
import {
  filterChannelsByActiveStatus,
  formatIsoDate,
  numberFormatter,
  sortAlphabetically,
  usdCurrencyFormatter
} from 'utils';
import './Campaign.scss';

export const Campaign = (): JSX.Element => {
  const editType = 'givingForm';

  const [channelSelectDialogOpen, setChannelSelectDialogOpen] = useState(false);
  const [showCopyEmbed, setShowCopyEmbed] = useState(false);
  const [showCopyEmbedGivingFormId, setShowCopyEmbedGivingFormId] =
    useState<string>();
  const [previewModalProps, setPreviewModalProps] = useState<{
    isOpen: boolean;
    givingFormId: string;
    configData: null;
    abTest: AbTest;
  }>({ isOpen: false, givingFormId: '', configData: null, abTest: null });
  const [selectedChannel, setSelectedChannel] = useState<string>('all');
  const [amountAccordionOpen, setAmountAccordionOpen] = useState(true);
  const [visitsAccordionOpen, setVisitsAccordionOpen] = useState(true);
  const [allGivingFormEdits, setAllGivingFormEdits] = useState<
    Array<{ id: string; name: string; editsSavedTime: string }>
  >([]);
  const [allAbTestEditIds, setAllAbTestEditIds] = useState<string[]>([]);
  const [showArchiveConfirmModal, setShowArchiveConfirmModal] = useState(false);
  const [archiveType, setArchiveType] = useState<ArchiveType>(null);
  const [archiveSubtype, setArchiveSubtype] = useState<ArchiveSubtype>(null);
  const [channelIdToArchive, setChannelIdToArchive] = useState<string>(null);
  const [p2pToArchive, setP2pToArchive] = useState<CampaignPeerToPeer>(null);
  const [pushAlert] = useAlerts();
  const navigate = useNavigate();
  const location = useLocation();
  const { campaignId } = useParams();
  const { selectedOrganization, setSelectedCampaignName } = useAppContext();
  const {
    data: { id: userId }
  } = useUser();
  const { renderIfRole } = useRenderIfRole();
  const {
    isLoading,
    data: campaignData,
    isError
  } = useCampaignById(selectedOrganization.id, campaignId);

  const {
    isLoading: isLoadingGivingForms,
    data: allGivingFormData,
    isError: givingFormError,
    isFetching: isFetchingGivingForms
  } = useCampaignGivingForms(selectedOrganization.id, campaignId, isLoading);

  const {
    isLoading: isLoadingPeerToPeers,
    data: allPeerToPeerData,
    isFetching: isFetchingPeerToPeer
  } = useCampaignPeerToPeers(selectedOrganization.id, campaignId, () =>
    pushAlert({
      title: 'Uh oh. Looks like there was an error loading your peer to peers.',
      severity: 'error'
    })
  );

  const { isLoading: isLoadingTextToGive, data: textToGiveData } =
    useCampaignTextToGive(selectedOrganization.id, campaignId, () =>
      pushAlert({
        title:
          'Uh oh. Looks like there was an error loading your text to give data.',
        severity: 'error'
      })
    );

  const {
    isLoading: isLoadingEvents,
    data: allEventsData,
    isFetching: isFetchingEvents
  } = useCampaignEvents(selectedOrganization.id, campaignId, () =>
    pushAlert({
      title: 'Uh oh. Looks like there was an error loading your event data.',
      severity: 'error'
    })
  );

  const {
    isLoading: isLoadingMetrics,
    data: summaryData,
    isError: metricsError
  } = useCampaignMetrics(
    selectedOrganization.id,
    campaignId,
    campaignData?.startDate,
    campaignData?.endDate,
    isLoading
  );

  const { data: abTestData, isLoading: isLoadingAbTests } = useGetAllAbTests(
    { organizationId: selectedOrganization.id },
    {
      onError: () => {
        pushAlert({
          title: 'Uh oh. Looks like there was an error loading your A/B tests.',
          severity: 'error'
        });
      }
    }
  );

  const { mutate: updateCampaign, isLoading: isUpdatingCampaign } =
    useUpdateCampaign(selectedOrganization.id, campaignId);

  const { mutateAsync: archiveGivingForm, isLoading: archiveGfIsLoading } =
    useArchiveGivingForm(selectedOrganization?.id);

  const { mutateAsync: archiveEvent, isLoading: archiveEventIsLoading } =
    useArchiveEvent(selectedOrganization?.id);

  const { mutateAsync: archiveP2P, isLoading: archiveP2PIsLoading } =
    useArchiveP2P(selectedOrganization?.id);

  const activeGivingForms = useMemo(() => {
    if (isLoadingGivingForms || !allGivingFormData) return [];
    return allGivingFormData.filter((form) =>
      filterChannelsByActiveStatus('active', form)
    );
  }, [allGivingFormData, isLoadingGivingForms]);

  const archivedGivingForms = useMemo(() => {
    if (isLoadingGivingForms || !allGivingFormData) return [];
    return allGivingFormData
      .filter((form) => filterChannelsByActiveStatus('archived', form))
      .sort(sortAlphabetically);
  }, [allGivingFormData, isLoadingGivingForms]);

  const activeEvents = useMemo(() => {
    if (isLoadingEvents || !allEventsData) return [];
    return allEventsData
      .filter((event) => filterChannelsByActiveStatus('active', event))
      .sort(
        (first, second) =>
          new Date(second.createdDate).getTime() -
          new Date(first.createdDate).getTime()
      );
  }, [allEventsData, isLoadingEvents]);

  const archivedEvents = useMemo(() => {
    if (isLoadingEvents || !allEventsData) return [];
    return allEventsData
      .filter((event) => filterChannelsByActiveStatus('archived', event))
      .sort(sortAlphabetically);
  }, [allEventsData, isLoadingEvents]);

  const activePeerToPeer = useMemo(() => {
    if (isLoadingPeerToPeers || !allPeerToPeerData) return [];
    return allPeerToPeerData.filter((p2p) =>
      filterChannelsByActiveStatus('active', p2p)
    );
  }, [allPeerToPeerData, isLoadingPeerToPeers]);

  const archivedPeerToPeer = useMemo(() => {
    if (isLoadingPeerToPeers || !allPeerToPeerData) return [];
    return allPeerToPeerData
      .filter((p2p) => filterChannelsByActiveStatus('archived', p2p))
      .sort(sortAlphabetically);
  }, [allPeerToPeerData, isLoadingPeerToPeers]);

  const metricsData = useMemo(() => {
    if (!isLoadingMetrics && summaryData) {
      const metrics = Object.values(summaryData).reduce<
        Omit<CampaignMetrics, 'conversionRate' | 'averageDonation'>
      >(
        (acc, currValue) => {
          acc.totalDonations += currValue.donationCount;
          acc.amountRaised += currValue.amountRaised;
          acc.pageVisits += currValue.visitCount;
          acc.repeatDonors += currValue.repeatDonorCount;
          acc.totalDonors += currValue.donorCount;
          return acc;
        },
        {
          totalDonations: 0,
          amountRaised: 0,
          pageVisits: 0,
          repeatDonors: 0,
          totalDonors: 0
        }
      ) as CampaignMetrics;

      if (metrics.pageVisits > 0) {
        metrics.conversionRate =
          (metrics.totalDonations / metrics.pageVisits) * 100;
      } else {
        metrics.conversionRate = 0;
      }

      metrics.averageDonation = metrics.amountRaised / metrics.totalDonations;
      return metrics;
    }

    return {} as CampaignMetrics;
  }, [isLoadingMetrics, summaryData]);

  const mapSummaryToMetric = (
    summary: ChannelSummaryBase
  ): CampaignMetrics => ({
    repeatDonors: summary.repeatDonorCount,
    pageVisits: summary.visitCount,
    totalDonations: summary.donationCount,
    conversionRate: summary.conversionRate * 100,
    amountRaised: summary.amountRaised,
    totalDonors: summary.donorCount,
    averageDonation: summary.amountRaised / summary.donationCount
  });

  const getDonorAccordionMetrics = () => {
    const emptyMetrics = {
      repeatDonors: 0,
      pageVisits: 0,
      totalDonations: 0,
      conversionRate: 0,
      amountRaised: 0,
      totalDonors: 0,
      averageDonation: 0
    };

    if (!summaryData) {
      return emptyMetrics;
    }
    const {
      eventSummary,
      givingFormSummary,
      otherSummary,
      peerToPeerSummary,
      textToGiveSummary
    } = summaryData;

    switch (selectedChannel) {
      case 'all':
        return metricsData;
      case 'giving-forms':
        return mapSummaryToMetric(givingFormSummary);
      case 'events':
        return mapSummaryToMetric(eventSummary);
      case 'peer-to-peer':
        return mapSummaryToMetric(peerToPeerSummary);
      case 'text-to-give':
        return mapSummaryToMetric(textToGiveSummary);
      case 'other':
        return mapSummaryToMetric(otherSummary);
      default:
        return emptyMetrics;
    }
  };

  const {
    isLoading: isLoadingPerChannelMetrics,
    data: perChannelMetricsData,
    isError: perChannelMetricsError
  } = usePerChannelMetrics(selectedOrganization.id, campaignId);

  useEffect(() => {
    const { redirectedFromPublishedAbTest, redirectedFromAbTestDraft } =
      (location.state ?? {}) as {
        redirectedFromPublishedAbTest: boolean;
        redirectedFromAbTestDraft: boolean;
      };

    if (redirectedFromPublishedAbTest) {
      pushAlert({
        title: 'Cannot Edit',
        description:
          'This form cannot be modified because it has an A/B Test in progress.',
        severity: 'error'
      });
    }
    if (redirectedFromAbTestDraft) {
      pushAlert({
        title: 'Cannot Edit',
        description:
          'This form cannot be modified because you are drafting an A/B Test.',
        severity: 'error'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getAbTestEditIds = async () => {
      try {
        const getAllEditsResponse = await editsRepositoryApi.getAllEdits(
          'abTest',
          userId
        );
        setAllAbTestEditIds(getAllEditsResponse.map((response) => response.id));
      } catch (e) {
        pushAlert({
          title: 'There was an error loading your A/B test drafts.',
          severity: 'error'
        });
      }
    };
    getAbTestEditIds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (campaignData) {
      setSelectedCampaignName(campaignData.title);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignData]);

  useEffect(() => {
    if (isError) {
      pushAlert({
        title: 'Uh oh. Looks like there was an error loading your campaign.',
        severity: 'error'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  useEffect(() => {
    if (givingFormError) {
      pushAlert({
        title:
          'Uh oh. Looks like there was an error loading your giving forms.',
        severity: 'error'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [givingFormError]);

  useEffect(() => {
    if (metricsError || perChannelMetricsError) {
      pushAlert({
        title:
          'Uh oh. Looks like there was an error loading your campaign metrics.',
        severity: 'error'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metricsError, perChannelMetricsError]);

  const onGivingFormPreviewClick = (givingFormId: string, abTest: AbTest) => {
    setPreviewModalProps({
      isOpen: true,
      givingFormId,
      configData: null,
      abTest
    });
  };
  const onTestResultsClick = (abTestId: string) => {
    navigate(`/strategy/ab-test/${abTestId}/overview`);
  };

  const getAllGivingFormEditsIds = async () => {
    try {
      const givingFormEdits = await editsRepositoryApi.getAllEdits(editType);
      setAllGivingFormEdits(givingFormEdits);
    } catch (e) {
      pushAlert({
        title: 'There was an error loading your local edits.',
        severity: 'error'
      });
    }
  };

  useEffect(() => {
    getAllGivingFormEditsIds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onGivingFormEditClick = (givingFormId: string, isLegacy: boolean) => {
    if (isLegacy) {
      navigate(
        `/campaigns/${campaignId}/giving-forms/${givingFormId}/edit-legacy`
      );
    } else {
      navigate(`/campaigns/${campaignId}/giving-forms/${givingFormId}/edit`);
    }
  };

  const onEmbedCodeClick = (givingFormId: string) => {
    setShowCopyEmbedGivingFormId(givingFormId);
    setShowCopyEmbed(true);
  };

  const handleArchiveCampaign = (archive: boolean) => {
    updateCampaign(
      {
        isArchived: archive,
        endDate: archive ? new Date().toISOString() : null
      },
      {
        onSuccess: () => navigate('/campaigns'),
        onError: () =>
          pushAlert({
            title: `Uh oh. Looks like there was an error ${
              archive ? 'archiving' : 'unarchiving'
            } your campaign.`,
            severity: 'error'
          })
      }
    );
    setShowArchiveConfirmModal(false);
  };

  const handleArchiveGivingForm = ({
    archive,
    gfId,
    isLegacy
  }: {
    archive: boolean;
    gfId: string;
    isLegacy: boolean;
  }) => {
    archiveGivingForm(
      { isArchived: archive, givingFormId: gfId, isLegacy },
      {
        onSuccess: () => {
          setChannelIdToArchive(null);
          setArchiveType(null);
          setArchiveSubtype(null);
          pushAlert({
            title: `Your giving form was successfully ${
              archive ? 'archived' : 'unarchived'
            }`,
            severity: 'success',
            autoHideDuration: 5000
          });
        },
        onError: () =>
          pushAlert({
            title: `Uh oh. Looks like there was an error ${
              archive ? 'archiving' : 'unarchiving'
            } your giving form.`,
            severity: 'error'
          })
      }
    );
  };

  const handleArchiveEvent = ({
    archive,
    evId
  }: {
    archive: boolean;
    evId: string;
  }) => {
    archiveEvent(
      { isArchived: archive, eventId: evId },
      {
        onSuccess: () => {
          setChannelIdToArchive(null);
          setArchiveType(null);
          setArchiveSubtype(null);
          pushAlert({
            title: `Your event was successfully ${
              archive ? 'archived' : 'unarchived'
            }`,
            severity: 'success',
            autoHideDuration: 5000
          });
        },
        onError: () =>
          pushAlert({
            title: `Uh oh. Looks like there was an error ${
              archive ? 'archiving' : 'unarchiving'
            } your event.`,
            severity: 'error'
          })
      }
    );
  };

  const handleArchivePeerToPeer = ({
    archive,
    p2p
  }: {
    archive: boolean;
    p2p: CampaignPeerToPeer;
  }) => {
    archiveP2P(
      {
        p2pId: p2p.id,
        name: p2p.name,
        slug: p2p.slug,
        startDate: p2p.createdDate,
        isArchived: archive
      },
      {
        onSuccess: () => {
          setChannelIdToArchive(null);
          setArchiveType(null);
          setArchiveSubtype(null);
          setP2pToArchive(null);
          pushAlert({
            title: `Your peer-to-peer was successfully ${
              archive ? 'archived' : 'unarchived'
            }`,
            severity: 'success',
            autoHideDuration: 5000
          });
        },
        onError: () =>
          pushAlert({
            title: `Uh oh. Looks like there was an error ${
              archive ? 'archiving' : 'unarchiving'
            } your peer-to-peer.`,
            severity: 'error'
          })
      }
    );
  };

  const breadcrumbsProps: BreadcrumbsProps = {
    breadcrumbs: [
      {
        label: 'All Campaigns',
        path: '/campaigns'
      },
      {
        label: campaignData?.title,
        path: '/campaigns/:campaignId'
      }
    ]
  };

  const primaryButtonProps: ButtonProps = {
    children: 'Add Channel',
    startIcon: <Icon icon={ICONS.PLUS} />,
    onClick: () => setChannelSelectDialogOpen(true)
  };

  const featureMenuItems: MenuItemProps[] =
    getRoleInt(selectedOrganization.role) >= getRoleInt(OrganizationRole.Editor)
      ? [
          {
            label: 'Edit Campaign',
            path: 'edit'
          },
          ...(campaignData?.isArchived
            ? [
                {
                  label: 'Unarchive Campaign',
                  onClick: () => handleArchiveCampaign(false)
                }
              ]
            : [
                {
                  label: 'Archive Campaign',
                  onClick: () => {
                    setArchiveType(ArchiveType.CAMPAGIN);
                    setShowArchiveConfirmModal(true);
                  }
                }
              ])
        ]
      : [];

  const abTestGivingFormIds =
    abTestData
      ?.filter((abTest) => !abTest.endedAt)
      .map((abTest) => abTest.givingForm.id) ?? [];

  const handleArchiveOnClick = (
    selectedType: ArchiveType,
    selectedSubtype: ArchiveSubtype,
    channelId: string,
    toArchive: boolean,
    p2pObject?: CampaignPeerToPeer
  ) => {
    setChannelIdToArchive(channelId);
    // if we're archiving, open the confirmation modal
    if (toArchive) {
      setArchiveType(selectedType);
      setArchiveSubtype(selectedSubtype);
      setShowArchiveConfirmModal(true);
      setP2pToArchive(p2pObject);
      // otherwise, immediately call the relevant service to unarchive
    } else if (selectedSubtype === ArchiveSubtype.EMBED) {
      handleArchiveGivingForm({
        archive: false,
        gfId: channelId,
        isLegacy: true
      });
    } else if (selectedSubtype === ArchiveSubtype.GIVING_FORM) {
      handleArchiveGivingForm({
        archive: false,
        gfId: channelId,
        isLegacy: false
      });
    } else if (selectedSubtype === ArchiveSubtype.EVENT) {
      handleArchiveEvent({ archive: false, evId: channelId });
    } else if (selectedSubtype === ArchiveSubtype.P2P_PROGRAM) {
      handleArchivePeerToPeer({ archive: false, p2p: p2pObject });
    }
  };

  const onConfirmArchive = () => {
    if (archiveType === ArchiveType.CAMPAGIN) {
      handleArchiveCampaign(true);
    } else if (archiveSubtype === ArchiveSubtype.EMBED) {
      handleArchiveGivingForm({
        archive: true,
        gfId: channelIdToArchive,
        isLegacy: true
      });
    } else if (archiveSubtype === ArchiveSubtype.GIVING_FORM) {
      handleArchiveGivingForm({
        archive: true,
        gfId: channelIdToArchive,
        isLegacy: false
      });
    } else if (archiveSubtype === ArchiveSubtype.EVENT) {
      handleArchiveEvent({ archive: true, evId: channelIdToArchive });
    } else if (archiveSubtype === ArchiveSubtype.P2P_PROGRAM) {
      handleArchivePeerToPeer({ archive: true, p2p: p2pToArchive });
    }
  };

  const onCloseArchiveModal = () => {
    setShowArchiveConfirmModal(false);
    setArchiveType(null);
    setArchiveSubtype(null);
    setChannelIdToArchive(null);
    setP2pToArchive(null);
  };

  const renderGivingForms = (
    campaignGivingForms: CampaignGivingForm[],
    archived: boolean
  ) => {
    if (campaignGivingForms?.length) {
      const title = archived ? 'Archived Giving Form' : 'Giving Form';
      return (
        <ChannelPreviewGrid
          title={pluralize(title, campaignGivingForms.length, true)}
        >
          {campaignGivingForms.map((givingForm: CampaignGivingForm) => {
            const matchingGivingFormEdit = allGivingFormEdits.find(
              ({ id }) => id === givingForm.id
            );

            // When the GF is not published, returns the saved time of edits from local storage.
            // Otherwise, if there are no edits in local storage return updateAt from database.
            const getDraftSavedTime = () => {
              if (!givingForm.isPublished) {
                if (matchingGivingFormEdit) {
                  return formatIsoDate(
                    matchingGivingFormEdit.editsSavedTime,
                    'MM/dd/yyyy'
                  );
                }
                const date = new Date(givingForm.updatedAt).toISOString();
                return formatIsoDate(date, 'MM/dd/yyyy');
              }
              return null;
            };

            const hasUnpublishedChanges =
              !!matchingGivingFormEdit && givingForm.isPublished;

            const givingFormName = hasUnpublishedChanges
              ? matchingGivingFormEdit.name
              : givingForm.name;

            const currentAbTestObject = abTestData
              ?.filter((abTest) => !abTest.endedAt)
              .find((abTest) => abTest.givingForm.id === givingForm.id);

            return (
              <GivingFormPreviewCard
                givingFormName={givingFormName}
                givingFormId={givingForm.id}
                createdDate={formatIsoDate(givingForm.createdAt, 'MM/dd/yyyy')}
                editedDate={getDraftSavedTime()}
                amountRaised={givingForm.amountRaised}
                donationAverage={givingForm.donationAverage}
                donationCount={givingForm.donationCount}
                visits={givingForm.visitCount}
                key={givingForm.id}
                onTestResultsClick={() =>
                  onTestResultsClick(currentAbTestObject?.abTestId)
                }
                onPreviewClick={() =>
                  onGivingFormPreviewClick(givingForm.id, currentAbTestObject)
                }
                onEditClick={(isLegacy) =>
                  onGivingFormEditClick(givingForm.id, isLegacy)
                }
                onEmbedCodeClick={() => onEmbedCodeClick(givingForm.id)}
                onArchiveClick={(toArchive: boolean) =>
                  handleArchiveOnClick(
                    ArchiveType.CHANNEL,
                    givingForm.isLegacy
                      ? ArchiveSubtype.EMBED
                      : ArchiveSubtype.GIVING_FORM,
                    givingForm.id,
                    toArchive
                  )
                }
                marginOnEven={campaignGivingForms.length % 2 === 0}
                isDraft={!givingForm.isPublished}
                isAbTest={abTestGivingFormIds.includes(givingForm.id)}
                isAbTestDraft={allAbTestEditIds.includes(givingForm.id)}
                unpublishedChanges={hasUnpublishedChanges}
                parentComp="campaignHome"
                isLegacy={givingForm.isLegacy}
                isSmsForm={givingForm.isSmsForm}
                hostedPageSlug={givingForm.hostedPageSlug}
                isArchived={givingForm.isArchived}
              />
            );
          })}
        </ChannelPreviewGrid>
      );
    }
    return null;
  };

  const renderPeerToPeers = (
    campaignPeerToPeer: CampaignPeerToPeer[],
    archived: boolean
  ) => {
    if (campaignPeerToPeer?.length) {
      const title = archived ? 'Archived Peer-to-Peer' : 'Peer-to-Peer';
      return (
        <ChannelPreviewGrid
          title={pluralize(title, campaignPeerToPeer.length, true)}
        >
          {campaignPeerToPeer.map((peerToPeer) => (
            <PeerToPeerPreviewCard
              id={peerToPeer.id}
              peerToPeerName={peerToPeer.name}
              createdDate={formatIsoDate(peerToPeer.createdDate, 'MM/dd/yyyy')}
              amountRaised={peerToPeer.amountRaised}
              donationCount={peerToPeer.donationsCount}
              donorCount={peerToPeer.donorCount}
              visitCount={peerToPeer.visitCount}
              key={peerToPeer.id}
              marginOnEven={campaignPeerToPeer.length % 2 === 0}
              isArchived={peerToPeer.isArchived}
              onArchiveClick={(toArchive: boolean) =>
                handleArchiveOnClick(
                  ArchiveType.CHANNEL,
                  ArchiveSubtype.P2P_PROGRAM,
                  peerToPeer.id,
                  toArchive,
                  peerToPeer
                )
              }
            />
          ))}
        </ChannelPreviewGrid>
      );
    }
    return null;
  };

  const renderEvents = (campaignEvents: CampaignEvent[], archived: boolean) => {
    if (campaignEvents?.length) {
      const title = archived ? 'Archived Event' : 'Event';

      return (
        <ChannelPreviewGrid
          title={pluralize(title, campaignEvents.length, true)}
        >
          {campaignEvents.map((event) => (
            <EventPreviewCard
              id={event.id}
              campaignId={campaignId}
              eventName={event.name}
              createdDate={event.createdDate}
              eventStartDate={event.eventStartDate}
              eventEndDate={event.eventEndDate}
              amountRaised={event.amountRaised}
              donorCount={event.donorCount}
              ticketsSold={event.ticketsSold}
              visitCount={event.visitCount}
              key={event.id}
              isArchived={event.isArchived}
              marginOnEven={campaignEvents.length % 2 === 0}
              onArchiveClick={(toArchive: boolean) =>
                handleArchiveOnClick(
                  ArchiveType.CHANNEL,
                  ArchiveSubtype.EVENT,
                  event.id,
                  toArchive
                )
              }
            />
          ))}
        </ChannelPreviewGrid>
      );
    }
    return null;
  };

  const renderTextToGive = (campaignTextToGive: CampaignTextToGive[]) => {
    if (campaignTextToGive?.length) {
      return (
        <ChannelPreviewGrid
          title={pluralize('Text to Give', campaignTextToGive.length, true)}
        >
          {campaignTextToGive.map((textToGive) => (
            <TextToGivePreviewCard
              textToGiveName={textToGive.keyword}
              createdDate={formatIsoDate(textToGive.created, 'MM/dd/yyyy')}
              amountRaised={textToGive.amountRaised}
              donorCount={textToGive.donors}
              donationCount={textToGive.donations}
              key={textToGive.id}
              marginOnEven={campaignTextToGive.length % 2 === 0}
              onEditClick={() =>
                navigate(
                  `/campaigns/${campaignId}/text-keywords/${textToGive.id}/edit`
                )
              }
            />
          ))}
        </ChannelPreviewGrid>
      );
    }
    return null;
  };

  const noAmountRaised = metricsData?.amountRaised === 0;

  const colorTest = (testColor: string) =>
    selectedChannel === testColor || selectedChannel === 'all';

  const getDoughnutColors = () =>
    noAmountRaised
      ? ['#DADCE8']
      : [
          colorTest('giving-forms') ? '#2356F6' : '#DADCE8', // Blue
          colorTest('events') ? '#AA23BF' : '#DADCE8', // Purple
          colorTest('peer-to-peer') ? '#EA9633' : '#DADCE8', // Yellow
          colorTest('text-to-give') ? '#3FAB8C' : '#DADCE8', // Green
          colorTest('other') ? '#DDBA1F' : '#DADCE8'
        ];

  const otherAmountRaised =
    (metricsData?.amountRaised || 0) -
    (perChannelMetricsData?.givingForms?.amountRaised || 0) -
    (perChannelMetricsData?.events?.amountRaised || 0) -
    (perChannelMetricsData?.peerToPeer?.amountRaised || 0) -
    (perChannelMetricsData?.textToGive?.amountRaised || 0);

  const doughnutData: ChartData<'doughnut'> = {
    datasets: [
      {
        data: noAmountRaised
          ? [1]
          : [
              perChannelMetricsData?.givingForms?.amountRaised,
              perChannelMetricsData?.events?.amountRaised,
              perChannelMetricsData?.peerToPeer?.amountRaised,
              perChannelMetricsData?.textToGive?.amountRaised,
              otherAmountRaised
            ],
        backgroundColor: getDoughnutColors(),
        borderColor: getDoughnutColors()
      }
    ],
    labels: ['Giving Forms', 'Events', 'Peer to Peer', 'Text to Give', 'Other']
  };

  const doughnutOptions: ChartOptions<'doughnut'> = {
    cutout: '75%',
    hover: {
      mode: null
    },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: selectedChannel === 'all' && !noAmountRaised,
        displayColors: false,
        callbacks: {
          label: (item) =>
            `${
              Math.round(
                (100 * (item.raw as number)) / (metricsData?.amountRaised ?? 0)
              ) ?? 0
            }%`
        }
      }
    }
  };

  const getChannelAccordionData = () => {
    switch (selectedChannel) {
      case 'giving-forms':
        return {
          ...perChannelMetricsData.givingForms,
          totalDonations:
            perChannelMetricsData.givingForms.amountRaised /
              perChannelMetricsData.givingForms.averageDonation || 0
        };
      case 'events':
        return {
          ...perChannelMetricsData.events,
          totalDonations:
            perChannelMetricsData.events.amountRaised /
              perChannelMetricsData.events.averageDonation || 0
        };
      case 'peer-to-peer':
        return {
          ...perChannelMetricsData.peerToPeer,
          totalDonations:
            perChannelMetricsData.peerToPeer.amountRaised /
              perChannelMetricsData.peerToPeer.averageDonation || 0
        };
      case 'text-to-give':
        return {
          ...perChannelMetricsData.textToGive,
          totalDonations:
            perChannelMetricsData.textToGive.amountRaised /
              perChannelMetricsData.textToGive.averageDonation || 0
        };
      case 'other':
        return {
          amountRaised: otherAmountRaised,
          averageDonation: 0,
          totalDonations: 0
        };
      default:
        return metricsData;
    }
  };

  const lengthOfAllChannels =
    (allGivingFormData?.length || 0) +
    (allPeerToPeerData?.length || 0) +
    (allEventsData?.length || 0) +
    (textToGiveData?.length || 0);

  const legendButtonConfigs = [
    {
      name: 'all',
      label: 'All',
      length: lengthOfAllChannels || 0
    },
    {
      name: 'giving-forms',
      label: 'Giving Forms',
      length: allGivingFormData?.length || 0
    },
    {
      name: 'peer-to-peer',
      label: 'Peer to Peer',
      length: allPeerToPeerData?.length || 0
    },
    {
      name: 'events',
      label: 'Events',
      length: allEventsData?.length || 0
    },
    {
      name: 'text-to-give',
      label: 'Text To Give',
      length: textToGiveData?.length || 0
    },
    {
      name: 'other',
      label: 'Other',
      length: 0
    }
  ];

  const getChannelLength = (name: string) =>
    legendButtonConfigs.find((config) => config.name === name)?.length;

  const selectChannelOptions = legendButtonConfigs.map((config) => ({
    value: config.name,
    label: `${config.label} (${getChannelLength(config.name)})`
  }));

  const getSelectedChannelValue = () => {
    const selection = selectChannelOptions.find(
      (option) => option.value === selectedChannel
    );
    return (
      <Stack direction="row" spacing={0.5}>
        <Text variant="body">{selection.label}</Text>
      </Stack>
    );
  };

  // Flag for conditionally rendering "empty channels" card
  // When adding new channel cards, add case to `channelsEmpty` flag
  // to only show the "empty channels" card when no channels are found
  const channelsEmpty =
    !allGivingFormData?.length &&
    !isLoadingGivingForms &&
    !allPeerToPeerData?.length &&
    !isLoadingPeerToPeers &&
    !allEventsData?.length &&
    !isLoadingEvents &&
    !textToGiveData?.length &&
    !isLoadingTextToGive;

  return (
    <Blocker
      block={
        isLoading ||
        isLoadingGivingForms ||
        isLoadingPeerToPeers ||
        isLoadingEvents ||
        isLoadingTextToGive ||
        isLoadingMetrics ||
        isLoadingPerChannelMetrics ||
        isLoadingAbTests ||
        isUpdatingCampaign ||
        isFetchingGivingForms ||
        archiveGfIsLoading ||
        archiveEventIsLoading ||
        isFetchingEvents ||
        isFetchingPeerToPeer ||
        archiveP2PIsLoading
      }
    >
      {!isError && campaignData && (
        <Box>
          <FeatureHeader
            titleProps={campaignData.title}
            subtitleProps={{
              customTitle: `Started on: ${formatIsoDate(
                campaignData.startDate,
                'MM/dd/yy',
                'UTC'
              )}`
            }}
            breadcrumbsProps={breadcrumbsProps}
            primaryButtonProps={
              campaignData.isActive ? primaryButtonProps : null
            }
            featureMenuItems={featureMenuItems}
          />

          <Box className="campaign-container fluid-container" display="flex">
            <Box className="campaign-details-container">
              <CampaignDetail campaignData={campaignData} />
              {renderIfRole(<CampaignNotifications />, OrganizationRole.Editor)}
              {/* If adding new channel cards to render, add case to `channelsEmpty` flag and loading flag in above `Blocker` component */}
              {(selectedChannel === 'giving-forms' ||
                selectedChannel === 'all') &&
                renderGivingForms(activeGivingForms, false)}
              {(selectedChannel === 'peer-to-peer' ||
                selectedChannel === 'all') &&
                renderPeerToPeers(activePeerToPeer, false)}
              {(selectedChannel === 'events' || selectedChannel === 'all') &&
                renderEvents(activeEvents, false)}
              {(selectedChannel === 'text-to-give' ||
                selectedChannel === 'all') &&
                renderTextToGive(textToGiveData)}
              {(selectedChannel === 'giving-forms' ||
                selectedChannel === 'all') &&
                renderGivingForms(archivedGivingForms, true)}
              {(selectedChannel === 'peer-to-peer' ||
                selectedChannel === 'all') &&
                renderPeerToPeers(archivedPeerToPeer, true)}
              {(selectedChannel === 'events' || selectedChannel === 'all') &&
                renderEvents(archivedEvents, true)}
              {channelsEmpty && (
                <Box className="channels-container">
                  <Text variant="h3">Your Channels</Text>
                  <Box className="no-channels" marginTop={1.5}>
                    <Text variant="h1">
                      Start adding channels to collect funds!
                    </Text>
                    <Text variant="h5">
                      This is a message to explain the value of adding channels
                      to your campaign. This should help educate a user in how
                      analytics are displayed and where they come from.
                    </Text>
                    <Button
                      onClick={() => setChannelSelectDialogOpen(true)}
                      startIcon={<Icon icon={ICONS.PLUS} />}
                    >
                      Add Channel
                    </Button>
                  </Box>
                </Box>
              )}
            </Box>
            <Box className="metrics-container">
              {perChannelMetricsData && (
                <>
                  <Select
                    onChange={(e) =>
                      setSelectedChannel(e.target.value as string)
                    }
                    defaultValue={selectedChannel}
                    className="channel-select"
                    options={selectChannelOptions}
                    renderValue={getSelectedChannelValue}
                  />
                  <Stack direction="row" justifyContent="space-between">
                    <Box className="doughnut-chart-container">
                      <Doughnut data={doughnutData} options={doughnutOptions} />
                    </Box>
                    <Stack className="chart-legend" spacing={0.75}>
                      {legendButtonConfigs.map((config) => (
                        <Text
                          className={`chart-legend-${config.name} ${
                            colorTest(config.name) ? '' : 'legend-disabled'
                          }`}
                          variant="caption"
                          key={config.name}
                          onClick={() => setSelectedChannel(config.name)}
                        >
                          <b>{config.label}</b>
                        </Text>
                      ))}
                    </Stack>
                  </Stack>
                </>
              )}

              {metricsData && (
                <Accordion
                  className="metrics-accordion"
                  expanded={amountAccordionOpen && selectedChannel !== 'other'}
                >
                  <AccordionSummary
                    onClick={() => {
                      setAmountAccordionOpen(!amountAccordionOpen);
                    }}
                    className={
                      selectedChannel === 'other' ? 'hidden-chevron' : ''
                    }
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      width="100%"
                    >
                      <Text variant="h5">Amount Raised</Text>
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Text variant="h5">
                          {`${usdCurrencyFormatter.format(
                            getChannelAccordionData().amountRaised || 0
                          )}`}
                        </Text>
                        <Icon
                          className="caret"
                          icon={
                            amountAccordionOpen
                              ? ICONS.CHEVRON_UP
                              : ICONS.CHEVRON_DOWN
                          }
                        />
                      </Stack>
                    </Stack>
                  </AccordionSummary>
                  {selectedChannel !== 'other' && (
                    <AccordionDetails>
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        width="100%"
                        className="row"
                      >
                        <Text variant="body">Average Donation</Text>
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Text variant="body">
                            {`${usdCurrencyFormatter.format(
                              getChannelAccordionData().averageDonation || 0
                            )}`}
                          </Text>
                          <Box className="caret" />
                        </Stack>
                      </Stack>
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        width="100%"
                        className="row"
                      >
                        <Text variant="body">Total Donations</Text>
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Text variant="body">
                            {`${numberFormatter.format(
                              Math.floor(
                                getChannelAccordionData().totalDonations || 0
                              )
                            )}`}
                          </Text>
                          <Box className="caret" />
                        </Stack>
                      </Stack>
                    </AccordionDetails>
                  )}
                </Accordion>
              )}

              {metricsData && (
                <Accordion
                  className="metrics-accordion"
                  expanded={visitsAccordionOpen}
                >
                  <AccordionSummary
                    onClick={() => {
                      setVisitsAccordionOpen(!visitsAccordionOpen);
                    }}
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      width="100%"
                    >
                      <Text variant="h5">Donors</Text>
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Text variant="h5">
                          {`${
                            numberFormatter.format(
                              getDonorAccordionMetrics()?.totalDonors
                            ) ?? 0
                          }`}
                        </Text>
                        <Icon
                          className="caret"
                          icon={
                            visitsAccordionOpen
                              ? ICONS.CHEVRON_UP
                              : ICONS.CHEVRON_DOWN
                          }
                        />
                      </Stack>
                    </Stack>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      width="100%"
                      className="row"
                    >
                      <Text variant="body">Repeat Donors</Text>
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Text variant="body">
                          {`${
                            numberFormatter.format(
                              getDonorAccordionMetrics()?.repeatDonors
                            ) ?? 0
                          }`}
                        </Text>
                        <Box className="caret" />
                      </Stack>
                    </Stack>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      width="100%"
                      className="row"
                    >
                      <Text variant="body">Visits</Text>
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Text variant="body">
                          {`${
                            numberFormatter.format(
                              getDonorAccordionMetrics()?.pageVisits
                            ) ?? 0
                          }`}
                        </Text>
                        <Box className="caret" />
                      </Stack>
                    </Stack>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      width="100%"
                      className="row"
                    >
                      <Text variant="body">Conversion Rate</Text>
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Text variant="body">
                          {`${numberFormatter.format(
                            getDonorAccordionMetrics()?.conversionRate ?? 0
                          )}`}
                          %
                        </Text>
                        <Box className="caret" />
                      </Stack>
                    </Stack>
                  </AccordionDetails>
                </Accordion>
              )}
            </Box>
          </Box>

          <ChannelSelectDialog
            open={channelSelectDialogOpen}
            onClose={() => setChannelSelectDialogOpen(false)}
            campaignName={campaignData.title}
          />

          <CopyEmbedDialog
            open={showCopyEmbed}
            onClose={() => setShowCopyEmbed(false)}
            givingFormId={showCopyEmbedGivingFormId}
          />

          <ArchiveCampaignOrChannelModal
            onConfirm={() => onConfirmArchive()}
            onClose={() => onCloseArchiveModal()}
            open={showArchiveConfirmModal}
            subtype={archiveSubtype}
            type={archiveType}
          />
          {previewModalProps.isOpen && (
            <Preview
              onClose={() =>
                setPreviewModalProps({
                  isOpen: false,
                  givingFormId: null,
                  configData: null,
                  abTest: null
                })
              }
              {...previewModalProps}
            />
          )}
        </Box>
      )}
    </Blocker>
  );
};
